import React from "react";

const Portfolio = () => (
  <div>
    <h1 className="title is-1" style={{color: 'black'}}>Portfolio</h1>
    <p style={{color: 'black'}}>
      ...Coming Soon
    </p>
  </div>
);

export default Portfolio;